<template>
  <v-app>
    <core-app-bar />

    <core-drawer />

    <core-snackbar />

    <core-view />
  </v-app>
</template>

<script>
export default {
  components: {
    CoreAppBar: () => import('@/components/core/CoreAppBar'),
    CoreDrawer: () => import('@/components/core/CoreDrawer'),
    CoreView: () => import('@/components/core/CoreView'),
    CoreSnackbar: () => import('@/components/core/CoreSnackbar'),
  },

  data: () => ({}),
}
</script>

<style>
*::-webkit-scrollbar {
  width: 8px;
}

*::-webkit-scrollbar-track {
  background: #ffffff;
}

*::-webkit-scrollbar-thumb {
  background-color: #b0b0b0;
  border-radius: 6px;
  border: 1px solid #ffffff;
}
</style>
